import { Checkbox, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useCallback } from "react"
import { Navigate } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, number, object, string } from "yup"
import { digitalManagerApi } from "../../../../../../services/api"
import {
  DiMaDetailsContent,
  DiMaDetailsContentProps,
} from "../../../../../components/DiMaDetailsContent"
import {
  AccessLevel,
  DimaFeatureShortNameEnum,
  hasUserAccessToFeature,
} from "../../../../../models/Authorization"
import { DeviceRoleGet, DeviceRolePut } from "../../../../../models/DeviceRole"
import { UserInfo } from "../../../../../models/User"

const validationSchema = object({
  name: string().required("Name is required"),
  isDefault: boolean().required("Is Default is required"),
  isAdmin: boolean().required("Is Admin is required"),
  isOwner: boolean().required("Is Owner is required"),
  isAssignable: boolean().required("Is Assignable is required"),
  maxOccurences: number().required("Max Occurences is required"),
})

export function DeviceRoleDetails(props: {
  readonly role: DeviceRoleGet
  readonly deviceId: string
  readonly user: UserInfo
  readonly setRole: (updatedRole: DeviceRoleGet) => void
}) {
  const { role, deviceId, user, setRole } = props

  const returnURL = `/devices/${deviceId}#roles`

  const deleteRole = useCallback(
    () => digitalManagerApi.delete(`/api/v1/devices/${deviceId}/roles/${role.id}`),
    [deviceId, role.id]
  )

  const formik = useFormik<DeviceRolePut>({
    validationSchema,
    initialValues: {
      name: role.name,
      description: role.description,
      fullDescription: role.fullDescription,
      isDefault: role.isDefault || false,
      isAdmin: role.isAdmin || false,
      isOwner: role.isOwner || false,
      isAssignable: role.isAssignable || false,
      maxOccurences: role.maxOccurences || 0,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) =>
      digitalManagerApi
        .put<DeviceRoleGet>(`/api/v1/devices/${deviceId}/roles/${role.id}`, {
          name: values.name,
          description: values.description,
          fullDescription: values.fullDescription,
          isDefault: values.isDefault,
          isAdmin: values.isAdmin,
          isOwner: values.isOwner,
          isAssignable: values.isAssignable,
          maxOccurences: values.maxOccurences,
        })
        .then((res) => {
          toast.success("Role updated")
          setRole(res.data)
        })
        .catch(() => {
          toast.error("Cannot update role")
          formik.resetForm()
          return Promise.reject(new Error("Cannot update role"))
        })
        .finally(() => {
          setSubmitting(false)
        }),
  })

  if (!role) {
    return <Navigate to={returnURL} />
  }

  const detailsContentProps: DiMaDetailsContentProps<DeviceRolePut> = {
    formik,
    label: "Role",
    canWrite: hasUserAccessToFeature(
      user.authorizations,
      DimaFeatureShortNameEnum.DM,
      AccessLevel.Write
    ),
    deleteAction: deleteRole,
    onDeleteReturnUrl: returnURL,
    listItems: [
      {
        label: "Name",
        displayItem: <Typography>{role.name}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="name"
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            size="small"
          />
        ),
      },
      {
        label: "Description",
        displayItem: <Typography>{role.description}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="description"
            name="description"
            type="text"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            size="small"
          />
        ),
      },
      {
        label: "Full Description",
        displayItem: <Typography>{role.fullDescription}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="fullDescription"
            name="fullDescription"
            type="text"
            value={formik.values.fullDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.fullDescription && Boolean(formik.errors.fullDescription)
            }
            helperText={formik.touched.fullDescription && formik.errors.fullDescription}
            size="small"
          />
        ),
      },
      {
        label: "Is Default",
        displayItem: <Typography>{role.isDefault?.toString() || "false"}</Typography>,
        editItem: (
          <Checkbox
            id="isDefault"
            name="isDefault"
            checked={formik.values.isDefault}
            onChange={formik.handleChange}
          />
        ),
      },
      {
        label: "Is Admin",
        displayItem: <Typography>{role.isAdmin?.toString() || "false"}</Typography>,
        editItem: (
          <Checkbox
            id="isAdmin"
            name="isAdmin"
            checked={formik.values.isAdmin}
            onChange={formik.handleChange}
          />
        ),
      },
      {
        label: "Is Owner",
        displayItem: <Typography>{role.isOwner?.toString() || "false"}</Typography>,
        editItem: (
          <Checkbox
            id="isOwner"
            name="isOwner"
            checked={formik.values.isOwner}
            onChange={formik.handleChange}
          />
        ),
      },
      {
        label: "Is Assignable",
        displayItem: <Typography>{role.isAssignable?.toString() || "false"}</Typography>,
        editItem: (
          <Checkbox
            id="isAssignable"
            name="isAssignable"
            checked={formik.values.isAssignable}
            onChange={formik.handleChange}
          />
        ),
      },
      {
        label: "Max Occurences",
        displayItem: <Typography>{role.maxOccurences}</Typography>,
        editItem: (
          <TextField
            fullWidth
            id="maxOccurences"
            name="maxOccurences"
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={formik.values.maxOccurences}
            onChange={formik.handleChange}
            error={formik.touched.maxOccurences && Boolean(formik.errors.maxOccurences)}
            helperText={formik.touched.maxOccurences && formik.errors.maxOccurences}
            size="small"
          />
        ),
      },
    ],
  }

  return <DiMaDetailsContent {...detailsContentProps} />
}
