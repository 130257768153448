import { LoadingButton } from "@mui/lab"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { useFormik } from "formik"
import { StatusCodes } from "http-status-codes"
import { useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { boolean, number, object, string } from "yup"
import { digitalManagerApi } from "../../../../../services/api"
import { BackToURL } from "../../../../components/BackToURL"
import { DeviceRolePost } from "../../../../models/DeviceRole"

const validationSchema = object({
  name: string().required("Name is required"),
  isDefault: boolean().required("Is Default is required"),
  isAdmin: boolean().required("Is Admin is required"),
  isOwner: boolean().required("Is Owner is required"),
  isAssignable: boolean().required("Is Assignable is required"),
  maxOccurences: number().required("Max Occurences is required"),
})

export function DeviceRoleCreate() {
  const { deviceId } = useParams()
  const navigate = useNavigate()
  const returnURL = `/devices/${deviceId}#roles`

  const formik = useFormik<DeviceRolePost>({
    validationSchema,
    initialValues: {
      name: "",
      description: "",
      fullDescription: "",
      isDefault: false,
      isAdmin: false,
      isOwner: false,
      isAssignable: false,
      maxOccurences: 0,
    },
    onSubmit: (values, { setSubmitting }) => {
      digitalManagerApi
        .post(`/api/v1/devices/${deviceId}/roles`, {
          name: values.name,
          description: values.description,
          fullDescription: values.fullDescription,
          isDefault: values.isDefault,
          isAdmin: values.isAdmin,
          isOwner: values.isOwner,
          isAssignable: values.isAssignable,
          maxOccurences: values.maxOccurences,
        })
        .then((res) => {
          if (res.status === StatusCodes.CREATED) {
            toast.success("Role created")
            navigate(returnURL)
          }
        })
        .catch(() => {
          toast.error("Cannot create role")
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const submitForm = useCallback(() => {
    formik.submitForm()
  }, [formik])

  return (
    <Box>
      <BackToURL url={returnURL} label="Back to roles" />
      <Paper>
        <Card>
          <CardHeader
            title="Create new role"
            action={
              <LoadingButton
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
                onClick={submitForm}
                disabled={!formik.dirty || !formik.isValid}
              >
                Save
              </LoadingButton>
            }
          />
          <CardContent
            sx={{
              padding: 0,
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Divider />
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItem key={0}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Name</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={1}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Description</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="description"
                        name="description"
                        type="text"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.description && Boolean(formik.errors.description)
                        }
                        helperText={
                          formik.touched.description && formik.errors.description
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={2}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Full Description</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="fullDescription"
                        name="fullDescription"
                        type="text"
                        value={formik.values.fullDescription}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.fullDescription &&
                          Boolean(formik.errors.fullDescription)
                        }
                        helperText={
                          formik.touched.fullDescription && formik.errors.fullDescription
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={3}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Default</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isDefault"
                        name="isDefault"
                        checked={formik.values.isDefault}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={4}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Admin</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isAdmin"
                        name="isAdmin"
                        checked={formik.values.isAdmin}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={5}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Owner</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isOwner"
                        name="isOwner"
                        checked={formik.values.isOwner}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={6}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Is Assignable</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        id="isAssignable"
                        name="isAssignable"
                        checked={formik.values.isAssignable}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <ListItem key={7}>
                <ListItemText>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Typography variant="h6">Max Occurences</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="maxOccurences"
                        name="maxOccurences"
                        type="text"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        value={formik.values.maxOccurences}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.maxOccurences &&
                          Boolean(formik.errors.maxOccurences)
                        }
                        helperText={
                          formik.touched.maxOccurences && formik.errors.maxOccurences
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}
